<template>
  <dl>
    <dt>Title</dt>
    <dd>{{ tip.title }}</dd>

    <dt>Subtitle</dt>
    <dd>{{ tip.subtitle }}</dd>

    <dt>Type</dt>
    <dd>{{ tip.type }}</dd>

    <dt>Image url</dt>
    <dd>{{ tip.imageUrl }}</dd>

    <div v-if="tip.imageUrl.length">
      <dt>Preview</dt>
      <dd>
        <v-img-ex
          :src="tip.imageUrl"
          max-width="400"
          max-height="400"
          v-on="$listeners"
        />
      </dd>
    </div>
  </dl>
</template>

<script>
import VImgEx from '@/components/VImgEx.vue'

export default {
  components: {
    VImgEx,
  },

  props: {
    tip: {
      required: true,
      type: Object,
    },
  },

  setup() {},
}
</script>
