import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'
import { UploadableFileTypes } from '@/enums/enums'

export default function useFiles() {
  const RESOURCE = '/files'

  const { showSnackbar } = useSnackbar()

  const selectedFile = ref([])
  const uploadProgress = ref(0)
  const isLoading = ref(false)

  let abortController = null

  const getFileTypeFromFileObject = fileObject => {
    const type = fileObject?.type ?? null

    if (!type) {
      return false
    }

    if (fileObject.type.startsWith('image/')) {
      return UploadableFileTypes.IMAGE
    }

    if (fileObject.type.startsWith('video/')) {
      return UploadableFileTypes.VIDEO
    }

    return false
  }

  const uploadFile = () => {
    const fileType = getFileTypeFromFileObject(selectedFile.value)

    if (!fileType) {
      showSnackbar(
        'Failed to upload the file: invalid file type',
        SnackbarType.ERROR,
      )

      throw new Error('Invalid file type')
    }

    isLoading.value = true

    const formData = new FormData()

    formData.append('file', selectedFile.value)

    abortController = new AbortController()

    return axios.post(`${RESOURCE}/${fileType}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: abortController.signal,
      onUploadProgress: event => {
        uploadProgress.value = Math.round((event.loaded / event.total) * 100)
      },
    })
      .then(response => {
        showSnackbar('You have successfully uploaded the file', SnackbarType.SUCCESS)

        selectedFile.value = []

        return response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to upload the file:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
        uploadProgress.value = 0
      })
  }

  // eslint-disable-next-line no-unused-vars
  const deleteFile = async () => {
    // TODO: ...
  }

  const listFiles = async fileType => {
    isLoading.value = true

    return axios.get(`${RESOURCE}/${fileType}`)
      .then(response => response.data)
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch list of files:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const cancelUpload = () => {
    isLoading.value = false
    uploadProgress.value = 0

    if (abortController) {
      abortController.abort()
    }
  }

  return {
    selectedFile,
    uploadProgress,
    isLoading,

    cancelUpload,
    uploadFile,
    deleteFile,
    listFiles,
  }
}
