<template>
  <v-tabs v-model="tabsNavigation" show-arrows>
    <v-tab>Fitness Assessment</v-tab>
    <v-tab>Exercise</v-tab>
    <v-tab>Tips</v-tab>

    <v-tabs-items v-model="tabsNavigation" class="pa-5">
      <v-tab-item>
        <dl>
          <dt>Id</dt>
          <dd>{{ item.id }}</dd>

          <dt>Unique key</dt>
          <dd>{{ item.uniqueKey }}</dd>

          <dt>Title of session</dt>
          <dd>{{ item.titleOfSession }}</dd>

          <dt>Session type</dt>
          <dd>{{ item.sessionType }}</dd>

          <dt>Category</dt>
          <dd>{{ item.category }}</dd>

          <dt>Level</dt>
          <dd>{{ item.level }}</dd>

          <dt>Frequency</dt>
          <dd>{{ item.frequency }}</dd>

          <dt>Measurement</dt>
          <dd>{{ item.measurement }}</dd>

          <dt>Practice type</dt>
          <dd>{{ item.practiceType }}</dd>

          <dt>Goal</dt>
          <dd>{{ item.goal }}</dd>

          <dt>Sort no.</dt>
          <dd>{{ item.sortNo }}</dd>

          <dt>Exercise priority</dt>
          <dd>{{ item.exercisePriority }}</dd>

          <dt>Scoring descriptions</dt>
          <dd>
            <ol>
              <li v-for="(scoringDescription, scoringDescriptionIndex) in item.scoringDescriptions"
                  :key="`scoringDescription-${scoringDescriptionIndex}`"
              >
                {{ scoringDescription }}
              </li>
            </ol>
          </dd>
        </dl>
      </v-tab-item>

      <v-tab-item>
        <exercise-details-tab-content :exercise="item.exercise" />
      </v-tab-item>

      <v-tab-item>
        <div
          v-for="(tip, tipIndex) in item.tips"
          :key="`tip-${tipIndex}`"
          class="mb-10"
        >
          <h3>
            Tip {{ tipIndex + 1 }}/{{ item.tips.length }}
          </h3>
          <v-divider class="mb-5"></v-divider>

          <tip-details-tab-content :tip="tip" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref } from 'vue'
import ExerciseDetailsTabContent from '@/components/ExerciseDetailsTabContent.vue'
import TipDetailsTabContent from '@/components/TipDetailsTabContent.vue'

export default {
  components: {
    ExerciseDetailsTabContent,
    TipDetailsTabContent,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  setup() {
    const tabsNavigation = ref(0)

    return {
      tabsNavigation,
    }
  },
}
</script>
