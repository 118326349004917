<template>
  <div class="file-uploader">
    <vee-file-input
      v-model="selectedFile"
      class="file-uploader__item file-uploader__item--input"
      :disabled="isLoading"
      show-size
      v-bind="$attrs"
      v-on="$listeners"
    />

    <v-btn
      class="file-uploader__item"
      color="success"
      dark
      small
      :disabled="!selectedFile || selectedFile.length === 0"
      :loading="isLoading"
      @click="onSubmit"
    >
      Upload
      <template v-slot:loader>
        {{ uploadProgress }}%
      </template>
    </v-btn>
    <v-btn
      class="file-uploader__item"
      color="error"
      dark
      small
      :disabled="!isLoading"
      @click="onCancel"
    >
      Cancel
    </v-btn>
  </div>
</template>

<script>
import { mdiWindowClose } from '@mdi/js'
import useFiles from '@/composables/api/files'
import VeeFileInput from '@/components/veeInputs/VeeFileInput.vue'

export default {
  emits: ['uploadCanceled', 'uploadSuccess', 'uploadFailed'],

  components: {
    VeeFileInput,
  },

  setup(props, { emit }) {
    const {
      selectedFile, uploadProgress, isLoading, uploadFile, cancelUpload,
    } = useFiles()

    const onSubmit = async () => {
      try {
        const uploadedFile = await uploadFile()
        emit('uploadSuccess', uploadedFile)
      } catch (error) {
        emit('uploadFailed', error)
      }
    }

    const onCancel = async () => {
      cancelUpload()
      emit('uploadCanceled')
    }

    return {
      isLoading,
      selectedFile,
      uploadProgress,

      uploadFile,

      onSubmit,
      onCancel,

      icons: {
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.file-uploader {
  display: flex;
  align-items: center;
}

.file-uploader__item {
  margin: 0 5px;
}

.file-uploader__item--input {
  flex: 1 1 auto;
}
</style>
